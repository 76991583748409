import { useEffect } from 'react'
import { embedDashboard } from '@superset-ui/embedded-sdk'
import './App.css'

const SUPERSET_DASHBOARD_ID = "965b2dc0-49b6-4a56-942d-07059bb30279"
const SUPERSET_DASHBOARD_URL = "https://dashboard.revealform.com"
const BACKEND_API_URL = "https://87nx2e4wa1.execute-api.us-west-1.amazonaws.com/getdashboard"

function App() {
  const getGuestToken = async () => {
    const response = await fetch(`${BACKEND_API_URL}`,
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({id:"965b2dc0-49b6-4a56-942d-07059bb30279"})
    }
    );
    const token = await response.json()
    return token.token
  }

  useEffect(() => {
    const embedSupersetDashboard = async () => {
      await embedDashboard({
        id: SUPERSET_DASHBOARD_ID,
        supersetDomain: SUPERSET_DASHBOARD_URL,
        mountPoint: document.getElementById('dashboard'),
        fetchGuestToken: () => getGuestToken(),
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: true,
          hideTab: true,
        },
      })
    }
    if (document.getElementById('dashboard')) {
      embedSupersetDashboard()
    }
  }, [])

  return (
    <div className='App'>
      <h1> ShopRite Dashboard - Demo</h1>
      <div id='dashboard' />
    </div>
  )
}

export default App
